import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import {defaultLanguage} from './languages';
import {initReactI18next} from 'react-i18next';
import qs from 'query-string';

const isDev = process.env.NODE_ENV === 'development';
const {locale = defaultLanguage} = qs.parse(window.location.search) as {locale: string};
const lng = locale.split('-')[0];

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng,
    fallbackLng: defaultLanguage,
    debug: isDev,
    saveMissing: false,
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    keySeparator: false,
    nsSeparator: false
  });

export default i18n;
