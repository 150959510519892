import {AppProvider} from '@shopify/polaris';
import {Provider as AppBridgeProvider} from '@shopify/app-bridge-react';
import React, {Suspense} from 'react';
import {Install} from './components/Install';
import {usePolarisLocale} from './hooks/usePolarisLocale';
import {getAppConfig, lazyWithPreload} from './lib/utils';
import './i18n';
import {config} from './stage.config';

const LazyApp = lazyWithPreload(() => import(/* webpackChunkName: "app" */ './App'));

export const Root: React.FC = () => {
  const {locale, host, shopifyApiKey, shopifyDomain, paymentMethod} = getAppConfig();
  const translations = usePolarisLocale(locale);
  if (host && window.top === window.self) {
    const installUrl = `${config.apiUrl}/install?shop=${shopifyDomain}&payment_method=${paymentMethod}`;
    window.location.assign(installUrl);
    return null;
  }
  return (
    // @ts-ignore
    <AppProvider i18n={translations}>
      <Suspense fallback={''}>
        {host ? (
          <AppBridgeProvider
            config={{
              apiKey: shopifyApiKey,
              forceRedirect: false,
              host
            }}>
            <LazyApp />
          </AppBridgeProvider>
        ) : (
          <Install />
        )}
      </Suspense>
    </AppProvider>
  );
};
