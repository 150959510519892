import React, {ComponentType, LazyExoticComponent} from 'react';
import qs, {ParsedQuery} from 'query-string';
import {config} from '../stage.config';
import camelcase from 'camelcase';

export interface ILazyComponent<T extends ComponentType<any>> extends LazyExoticComponent<T> {
  preload: () => Promise<any>;
}

/**
 * Lazy load component and add preload method
 * Call Component.preload() to fetch async chunks
 * @param factory
 */
export const lazyWithPreload = <T extends ComponentType<any>>(
  factory: () => Promise<{default: T}>
) => {
  const Component = React.lazy(factory) as ILazyComponent<T>;
  Component.preload = factory;
  return Component;
};

/**
 * Merges arrays removing duplicates
 * @param arrays
 */
export const mergeArrays = (...arrays: any[]) => {
  return Array.from(new Set([].concat(...arrays)));
};

/**
 * a simple wrapper to use for async functions
 * const [err, data] = await withError(asyncAction());
 * @param p - a promise
 */
export async function withError<T>(p: Promise<T>): Promise<[Error | undefined, T | any]> {
  try {
    return [undefined, await p];
  } catch (err: any) {
    return [err, undefined];
  }
}

interface IQueryParams extends ParsedQuery {
  shop: string;
  host: string;
  token: string | null;
  locale: string;
}

interface AppConfig extends IQueryParams {
  shopifyApiKey: string;
  paymentMethod: string;
  paymentMethodLabel: string;
  shopifyDomain: string | null;
}

export const paymentMethodLabels: Record<string, string> = {
  monei: 'MONEI',
  card: 'Card',
  bizum: 'Bizum',
  paypal: 'PayPal',
  qr: 'QR',
  qrs: 'QR',
  cofidis: 'Cofidis 4xcard',
  cofidisLoan: 'Cofidis 6x12x24x',
  onsite: 'MONEI Onsite',
};

export const getAppConfig = () => {
  const params = qs.parse(window.location.search) as IQueryParams;
  const paymentMethod = camelcase(window.location.pathname.substring(1) || 'monei');
  const shopifyApiKey = (config.shopifyApiKeys as any)[paymentMethod];
  const appConfig: AppConfig = {
    ...params,
    shopifyApiKey,
    paymentMethod,
    paymentMethodLabel: paymentMethodLabels[paymentMethod],
    shopifyDomain: null
  };
  if (params.host) {
    appConfig.shopifyDomain = atob(params.host).replace('/admin', '');
  }
  return appConfig;
};
