import {
  Button,
  Card,
  DisplayText,
  FooterHelp,
  Form,
  FormLayout,
  Link,
  Page,
  TextContainer
} from '@shopify/polaris';
import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import * as yup from 'yup';
import {config} from '../stage.config';
import {InputField} from './InputField';
import {SelectField} from './SelectField';
import {getAppConfig, paymentMethodLabels} from '../lib/utils';
import {Trans, useTranslation} from 'react-i18next';

type FormValues = {
  shop: string;
  paymentMethod: string;
};

const validationSchema = yup.object({
  shop: yup
    .string()
    .matches(/[a-z0-9][a-z0-9-]*\.myshopify\.com(\/)?$/i, 'invalid shop domain')
    .required(),
  paymentMethod: yup.string().required()
});

const {paymentMethod} = getAppConfig();

export const Install: React.FC = () => {
  const {t} = useTranslation();
  const handleSubmit = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
    setSubmitting(true);
    const shop = values.shop.replace(/^\/\/|^.*?:(\/\/)?/, '').split(/[/?#]/)[0];
    const paymentMethod = values.paymentMethod;
    window.location.href = `${config.apiUrl}/install?shop=${shop}&payment_method=${paymentMethod}`;
  };
  return (
    <div style={{overflow: 'hidden'}}>
      <Page title="Install" narrowWidth titleHidden>
        <div style={{textAlign: 'center', marginTop: 100}}>
          <TextContainer spacing="loose">
            <img
              src="https://assets.monei.com/images/logo.svg"
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={200}
            />
            <DisplayText>
              <Trans>
                Grow your e-commerce business faster with the advanced Shopify payment gateway
              </Trans>
            </DisplayText>
            <Card sectioned>
              <Formik
                onSubmit={handleSubmit}
                initialValues={{shop: '', paymentMethod}}
                validationSchema={validationSchema}>
                {({handleSubmit, isSubmitting}) => (
                  <div style={{textAlign: 'initial'}}>
                    <Form onSubmit={handleSubmit}>
                      <FormLayout>
                        <InputField
                          label={t(
                            'Enter your shop domain to log in or install MONEI Payments App'
                          )}
                          type="text"
                          name="shop"
                          placeholder="example.myshopify.com"
                        />
                        <Button submit primary fullWidth loading={isSubmitting}>
                          {t('Install')}
                        </Button>
                      </FormLayout>
                    </Form>
                  </div>
                )}
              </Formik>
            </Card>
            <FooterHelp>
              <Trans>
                Have any questions? Contact us at{' '}
                <Link external url="mailto:support@monei.com">
                  support@monei.com
                </Link>{' '}
                or visit our{' '}
                <Link external url="https://support.monei.com">
                  help center
                </Link>
                .
              </Trans>
            </FooterHelp>
          </TextContainer>
        </div>
      </Page>
    </div>
  );
};
