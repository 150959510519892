import {useEffect, useState} from 'react';
import {defaultLanguage} from '../languages';

const polarisLocaleMap: Record<string, string> = {
  pt: 'pt-PT',
  zh: 'zh-CN'
};

export const usePolarisLocale = (locale: string = defaultLanguage) => {
  const [translations, setTranslations] = useState<any>();
  const lngKey = locale.split('-')[0];
  const lng = polarisLocaleMap[lngKey] || lngKey;

  useEffect(() => {
    import(
      /* webpackChunkName: "Polaris-i18n", webpackMode: "lazy-once" */ `@shopify/polaris/locales/${lng}.json`
    ).then((result) => setTranslations(result.default));
  });

  return translations;
};
