type Stage = 'dev' | 'prod';

const stage = (process.env.REACT_APP_STAGE as Stage) || 'dev';

const globalConfig = {
  paymentMethods: ['monei', 'card', 'bizum']
};

const stageConfig = {
  dev: {
    apiUrl: 'https://shopify-service.microapps-staging.com',
    shopifyApiKeys: {
      card: '658fffe5b7447548a56c39025b6c8c3f',
      onsite: '889387935bcd7ffe61b216df9f257ba9'
    }
  },
  prod: {
    apiUrl: 'https://shop-service.monei.com',
    shopifyApiKeys: {
      monei: 'b7005b885b65e1f4a96042a52f98f442',
      card: '152dfff50d22408635d9ebfab06f68ed',
      bizum: '836fcb08b104f23504e6831438955799',
      qr: '0fe551d05beb0b5e1f82a17a8234be8e',
      qrs: '9eefdc8b3809141b06ca2a078f17bde3',
      onsite: '889387935bcd7ffe61b216df9f257ba9'
    }
  }
};

export const config = {...globalConfig, ...stageConfig[stage]};
