import {TextField, TextFieldProps} from '@shopify/polaris';
import {useField} from 'formik';
import React from 'react';

export type InputFieldProps = Partial<TextFieldProps> & {
  name: string;
};

export const InputField: React.FC<InputFieldProps> = ({name, ...props}) => {
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;
  return (
    // @ts-ignore
    <TextField {...field} onChange={helpers.setValue} {...props} error={error} />
  );
};
